import React from "react";
import UserBubble from "./UserBubble";
// import Dropdown from "./Dropdown";
import { Link } from 'react-router-dom'
import LanguageSelector from "./LanguageSelector";
import BackendSelector from "./BackendSelector";
import { useLanguage } from "../context/Language";

const Navbar = () => {
  const getText = useLanguage().getText;

  return (
    <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light" style={{ zIndex: 1046 }}>
      <div className="container">
        <Link className="navbar-brand" to='/'>
          <img src={process.env.PUBLIC_URL + "/knowledge-sea.svg"} alt="" width="30" height="24"
            className="d-inline-block align-text-top me-2" />
          {getText("knowledge-sea")}
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mb-2 mb-md-0 ms-auto">
            <BackendSelector />
            <LanguageSelector />
            {/* <Dropdown /> */}
            <UserBubble />
          </ul>
        </div>
      </div>
    </nav >
  )
}

export default Navbar;
