import { Link } from 'react-router-dom';
import { useDomain } from '../context/Domain';
import { useLanguage } from '../context/Language';

export default function Home() {
  const isKnowledgeSea = useDomain().isKnowledgeSea;
  const isAffexy = useDomain().isAffexy;
  const isBps = useDomain().isBps;
  const isPzu = useDomain().isPzu;
  const isEnea = useDomain().isEnea;
  const isCvi = useDomain().isCvi;
  const isOlawa = useDomain().isOlawa;
  const getText = useLanguage().getText;

  return (
    <div className="App-content container mt-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {(isKnowledgeSea || isAffexy) &&
          <>
            <HomeCard
              title={getText("scientific-paper-ai")}
              img="/images/scientific.jpeg"
              description={getText("scientific-paper-ai-description")}
              route="/science-ai" />
          </>
        }
        {isAffexy &&
          <>
            <HomeCard
              title={getText("banking-ai")}
              img="/images/banking.jpeg"
              description={getText("banking-ai-description")}
              route="/banking-ai" />
            <HomeCard
              title={getText("infrastructure-ai")}
              img="/images/energy.jpeg"
              description={getText("infrastructure-ai-description")}
              route="/infrastructure-ai" />
          </>
        }
        {isBps &&
          <>
            <HomeCard
              title={getText("bps-ai")}
              img="/images/bps_bank.png"
              description={getText("bps-ai-description")}
              route="/bps-ai" />
          </>
        }
        {isPzu &&
          <>
            <HomeCard
              title={getText("pzu-ai")}
              img="/images/pzu.png"
              description={getText("pzu-ai-description")}
              route="/pzu-ai" />
          </>
        }
        {isEnea &&
          <>
            <HomeCard
              title={getText("enea-ai")}
              img="/images/enea.png"
              description={getText("enea-ai-description")}
              route="/enea-ai" />
          </>
        }
        {isCvi &&
          <>
            <HomeCard
              title={getText("cvi-ai")}
              img="/images/cvi.png"
              description={getText("cvi-ai-description")}
              route="/cvi-ai" />
          </>
        }
        {isOlawa &&
          <>
            <HomeCard
              title={getText("olawa-ai")}
              img="/images/bps_olawa.png"
              description={getText("olawa-ai-description")}
              route="/olawa-ai" />
          </>
        }
      </div>
    </div>
  );
}

function HomeCard({ title, description, img, route }) {
  return (
    <div className="col">
      <div className="card">
        <img src={img} className="card-img-top" alt={title + " Image"} />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
          <Link to={route} className="stretched-link"></Link>
        </div>
      </div>
    </div>
  )
}