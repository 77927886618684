import { Outlet, Link, RouterProvider, Navigate, createBrowserRouter } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useEffect } from "react";
import axios from 'axios';

import { AuthProvider, useAuth } from './context/Auth';
import { DomainProvider, useDomain } from "./context/Domain";
import CircularProgress from '@mui/material/CircularProgress';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Home from "./components/Home";
import ScienceAi from './components/science-ai/ScienceAi';
import ChatbotAi from './components/chatbot-ai/ChatbotAi';
import InfrastructureAi from './components/infrastructure-ai/InfrastructureAi';
import './App.css';
import { LanguageProvider } from "./context/Language";
import { BackendProvider } from "./context/Backend";
import BpsManual from "./components/BpsManual";
import PdfViewer from "./components/PdfViewer";

export const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        name: 'home',
        element: <Home />,
      },
      {
        path: '/login',
        name: 'login',
        element: <RequireAuth><Navigate to="/"></Navigate></RequireAuth>,
      },
      {
        path: '/science-ai',
        name: 'science-ai',
        element: <RequireAuth><ScienceAi /></RequireAuth>,
        requireNavAuth: true,
      },
      {
        path: '/banking-ai',
        name: 'banking-ai',
        element: <RequireAffexyDomain><RequireAuth isAffexy={true}><ChatbotAi application_name="pl_banking_law" /></RequireAuth></RequireAffexyDomain>,
        requireNavAuth: true,
        requireAffexyDomain: true,
      },
      {
        path: '/bps-ai',
        name: 'bps-ai',
        element: <RequireBpsDomain><RequireAuth isBps={true}><ChatbotAi application_name="bps_mobile_manual" /></RequireAuth></RequireBpsDomain>,
        requireNavAuth: true,
        requireBpsDomain: true,
      },
      {
        path: '/pzu-ai',
        name: 'pzu-ai',
        element: <RequirePzuDomain><RequireAuth isPzu={true}><ChatbotAi application_name="pzu_insurance" /></RequireAuth></RequirePzuDomain>,
        requireNavAuth: true,
        RequirePzuDomain: true,
      },
      {
        path: '/enea-ai',
        name: 'enea-ai',
        element: <RequireEneaDomain><RequireAuth isPzu={true}><ChatbotAi application_name="enea_energy" /></RequireAuth></RequireEneaDomain>,
        requireNavAuth: true,
        RequireEneaDomain: true,
      },
      {
        path: '/cvi-ai',
        name: 'cvi-ai',
        element: <RequireCviDomain><RequireAuth isCvi={true}><ChatbotAi application_name="cvi" /></RequireAuth></RequireCviDomain>,
        requireNavAuth: true,
        requireCviDomain: true,
      },
      {
        path: '/olawa-ai',
        name: 'olawa-ai',
        element: <RequireOlawaDomain><RequireAuth isOlawa={true}><ChatbotAi application_name="olawa" /></RequireAuth></RequireOlawaDomain>,
        requireNavAuth: true,
        requireOlawaDomain: true,
      },
      {
        path: '/bps-ai/manual',
        name: 'bps-ai-manual',
        element: <RequireBpsDomain><RequireAuth isBps={false}><BpsManual></BpsManual></RequireAuth></RequireBpsDomain>,
        requireNavAuth: true,
        requireBpsDomain: true,
      },
      {
        path: '/infrastructure-ai',
        name: 'infrastructure-ai',
        element: <RequireAffexyDomain><RequireAuth isAffexy={true}><InfrastructureAi /></RequireAuth></RequireAffexyDomain>,
        requireNavAuth: true,
        requireAffexyDomain: true,
      },
      {
        path: '/document/:subpath',
        name: 'document',
        element: <RequireAuth><PdfViewer/></RequireAuth>,
      },
      {
        path: '*',
        name: 'not-found',
        element: <NoMatch />,
      },
    ],
  },
];

function RequireAffexyDomain({ children }) {
  const domain = useDomain();

  if (domain.isAffexy) {
    return children
  } else {
    return <Navigate to="/"></Navigate>
  }
}

function RequireBpsDomain({ children }) {
  const domain = useDomain();

  if (domain.isBps) {
    return children
  } else {
    return <Navigate to="/"></Navigate>
  }
}

function RequirePzuDomain({ children }) {
  const domain = useDomain();

  if (domain.isPzu) {
    return children
  } else {
    return <Navigate to="/"></Navigate>
  }
}

function RequireEneaDomain({ children }) {
  const domain = useDomain();

  if (domain.isEnea) {
    return children
  } else {
    return <Navigate to="/"></Navigate>
  }
}

function RequireCviDomain({ children }) {
  const domain = useDomain();

  if (domain.isCvi) {
    return children
  } else {
    return <Navigate to="/"></Navigate>
  }
}

function RequireOlawaDomain({ children }) {
  const domain = useDomain();

  if (domain.isOlawa) {
    return children
  } else {
    return <Navigate to="/"></Navigate>
  }
}

function RequireAuth({ children, isAffexy }) {
  const auth = useAuth();

  const circularProgress = <>
    <div className='spacer-sm'></div>
    <div className="full-height-sm">
      <div className='h-100 d-flex align-items-center justify-content-center'>
        <div>
          <CircularProgress />
        </div>
      </div>
    </div>
  </>;

  if (auth.isAuthenticated == null) {
    return circularProgress;
  }

  if (auth.isAuthenticated === true && auth.isAffexy == null) {
    return circularProgress;
  }

  if (auth.isAuthenticated === false) {
    return <Login />
  }

  if (isAffexy && !auth.isAffexy) {
    return <Navigate to="/"></Navigate>
  }

  return children
}

function App() {
  const router = createBrowserRouter(routes);
  const [cookie] = useCookies(['csrftoken']);
  useEffect(() => {
    axios.defaults.headers.common['X-CSRFToken'] = cookie.csrftoken
  }, [cookie]);

  return (
    <div>
      <LanguageProvider>
        <DomainProvider>
          <AuthProvider>
            <BackendProvider>
              <RouterProvider router={router} />
            </BackendProvider>
          </AuthProvider>
        </DomainProvider>
      </LanguageProvider>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  )
}

function NoMatch() {
  return (
    <div className="App-content container">
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
