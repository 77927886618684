import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import axios from 'axios';

export default function InfrastructureAi() {
    const [pdfUrl, setPdfUrl] = useState('');

    const { subpath } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const file = urlParams.get('file');
    const hash = window.location.hash;
    const match = hash.match(/page=(\d+)/);
    const page = match ? match[1] : '1';

    useEffect(() => {
        axios
            .get(`/api/docs/${subpath}?file=${file}`, {
                responseType: 'blob',
            })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob) + `#page=${page}`;
                setPdfUrl(url);
            })
    }, [subpath, file, page]);

    return (<div className='full-height'>
        <iframe src={pdfUrl} width="100%" height="100%" title="pdf"></iframe>
    </div>)
}
